/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */


//window.$ = window.jQuery = require('jquery');

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/collapse';

var lang = document.documentElement.lang.substr(0, 2);

const linkElements = document.querySelectorAll(".type-item");
for(var i = 0; i < linkElements.length; i++) {
    var elem = linkElements[i];
    elem.onclick = function() {
        document.querySelector("#select-type").innerHTML=this.dataset.value;
    document.querySelector("#select-type").value=this.dataset.value;
    document.querySelector("#select-type").setAttribute('data-type-id', this.dataset.typeId);
    document.querySelector("#select-type").setAttribute('data-type-name', this.dataset.type);
    }
}

document.querySelector( "#myForm" ).onsubmit = function(event){
    event.preventDefault();
    // Build the url using the form action and the topic value
    var q = document.querySelectorAll('input[name="q"]')[0];
    if(lang=="en")lang="";
    if(q.value.length>0){
        window.location.href = lang + "/" + encodeURIComponent(document.querySelector("#select-type").getAttribute('data-type-name') ) + "/" + encodeURIComponent(q.value);
    }else{
        window.location.href = lang + "/" + encodeURIComponent(document.querySelector("#select-type").getAttribute('data-type-name') );
    }
};

